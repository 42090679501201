import React from 'react';
import loginimage from '../assets/img/login.jpg';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { login } from '../../Redux/Action/AuthAction/AuthAction';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error } = useSelector((state) => state.userSigninReducer);

  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is Required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = 'Password is Required';
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: (values) => {
      dispatch(login(values, history));
    },
  });
  return (
    <div className='page-wrapper1'>
      <div className=' container-fluid'>
        <div className='row'>
          <div className='col-md-7 p-0'>
            <div className='img_flame'>
              <img
                src={loginimage}
                className='h-100 w-100 object-cover'
                alt=''
              />
            </div>
          </div>
          <div className='col-md-5 justify-content-center  align-items-center d-flex'>
            <div className='login_data p-5'>
              <div className='logn_text'>
                <img src='../../../assets/img/logo.png' height='90' alt='' />
                <h3 className='mt-5'>Sign in to your account</h3>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className='ema_data mt-4'>
                  <div className='form-group'>
                    <label for=''>Email Address</label>
                    <input
                      name='email'
                      type='email'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <p style={{ color: 'red' }}>{formik.errors.email}</p>
                    ) : null}
                    <p style={{ color: 'red' }}>
                      {' '}
                      {formik.errors.email ? '' : error && error?.email}
                    </p>
                  </div>
                  <div className='form-group'>
                    <label for=''>Password</label>
                    <input
                      name='password'
                      type='password'
                      className='form-control'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.password}
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <p style={{ color: 'red' }}>{formik.errors.password}</p>
                    ) : null}
                    <p style={{ color: 'red' }}>
                      {' '}
                      {formik.errors.password ? '' : error && error?.password}
                    </p>
                  </div>
                  <div className='form-group'>
                    <button
                      disabled={
                        !formik.isValid ||
                        (Object.keys(formik.values).length === 0 &&
                          formik.touched.constructor === Object)
                      }
                      className='btn btn-primary form-control'
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
