import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  editReportChat,
  getAllChatReports,
  getReportChatById,
  deleteReportData,
  getClinetList,
  getReportsList,
} from '../../Redux/Action/SuperAdminAction';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { IMAGE_URL } from '../../config';

function Report_overview(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectcontractor, setselectcontractor] = useState(false);
  const [comments, setComments] = useState('');
  const [chat_id, setChat_id] = useState('');
  const [del, setDel] = useState('');
  const [showDelete, setDeleteShow] = useState(false);


  const uniqueJobTypes = useSelector((state) => state?.adminList?.GetReportsChat?.data);
  const client_name = useSelector((state) => state?.adminList?.GetReportsChat?.clientnamedata);

  const handleUpdateClose = () => {
    dispatch(editReportChat({ chat_id: chat_id, message: comments }));
    setselectcontractor(false);
  };

  const handleShow = (data) => {
    dispatch(getReportChatById(data._id));
    setComments(data.Comment);
    setChat_id(data._id);
    setselectcontractor(true);
    localStorage.setItem('report_view_section', props.match.params.id);
  };

  const handleClose = () => setselectcontractor(false);

  const deleteReport = (ror_id) => {
    setDel(ror_id);
    setDeleteShow(true);
  };

  const handledeleteClose = () => {
    setDeleteShow(false);
  };

  const handleButtonDeleteClick = (id) => {
    console.log(id, 'newid');
    dispatch(deleteReportData(id));
    handledeleteClose(false);
  };

  useEffect(() => {
    dispatch(getClinetList());
    dispatch(getReportsList());
  }, []);

  useEffect(() => {
    dispatch(getAllChatReports(props.match.params.id));
  }, [props.match.params.id]);

  return (
    <div className='page-wrapper'>
      {/* <!-- Page Wrapper --> */}

      {/* <!-- Page Content --> */}
      <div class='content container-fluid'>
        {/* <!-- Page Header --> */}

        <div class='page-header'>
          <div class='row align-items-center d-flex'>
            <div class='col-md-6'>
              <h3 class='page-title'>Reports</h3>
            </div>

          </div>
        </div>

        {/* <!-- /Page Header --> */}
        <div class='row'>
          <div class='col-md-12 d-flex'>
            <div class=' card-table flex-fill'>
              <div class='card-body'>
                <div className='row d-flex align-items-center'>
                  <div className='col-md-2 d-flex'>
                    <NavLink class="btn add-btn" to={localStorage.getItem("whichPage") == "reports" ? "/reports" : `/report_export/${localStorage.getItem("create_new_report")}`} >Back</NavLink>
                  </div>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Job Number</label>
                      <div className='total-time-view form-control'>
                        {localStorage.getItem("jobNo_PDF")}

                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Orchard Number</label>
                      <div className='total-time-view form-control'>
                        {localStorage.getItem("chardNo_PDF")}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <label for=''>Client</label>
                      <div className='total-time-view form-control'>
                        {client_name}
                      </div>
                    </div>
                  </div>
                  <div className='col-md-2'>
                    <div className='form-group'>
                      <label for=''>Type</label>
                      <div className='total-time-view form-control'>
                        {localStorage.getItem("jobType_PDF")}
                      </div>
                    </div>
                  </div>
                </div>
                <div class='table-responsive'>
                  <table class='  datatable table table-stripped mb-0 custom-table-font'>
                    <thead class='custom_color'>
                      <tr>
                        <th></th>
                        <th>IMAGE</th>
                        <th>TYPE</th>
                        <th>PRODUCT USED</th>
                        <th>NOTES</th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {uniqueJobTypes &&
                        uniqueJobTypes?.map((item, i) => {
                          return (
                            <>
                              <tr class='text-middle-custom'>
                                <td class='text-center'>{i + 1}</td>
                                <td class='table-td-img'>
                                  <img
                                    src={`${IMAGE_URL}${item?.chat_id?.Image}`}
                                    alt=''
                                  />
                                </td>
                                <td>
                                  {item?.chat_id?.type == 1
                                    ? 'Job Started'
                                    : item?.chat_id?.type == 4
                                      ? 'General'
                                      : item?.chat_id?.type == 3
                                        ? 'Product Used'
                                        : item?.chat_id?.type == 5 &&
                                        'Job Completed'}
                                </td>
                                <td>{item?.chat_id?.product_id?.name}</td>
                                <td class='comment-scroll-hide'>
                                  <div class='comment-part-td slimscroll'>
                                    {item?.Comment}
                                  </div>
                                </td>
                                {/* <td style={{cursor:"pointer"}} onClick={() =>
                                    history.push(
                                      `/report_view_edit_section/${item._id}`
                                    )
                                  }>
                                    <div class='blue-text-an'>
                                      Edit
                                    </div>
                                  </td>
                                   */}

                                <td
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => handleShow(item)}
                                >
                                  <div class='blue-text-an'>Edit</div>
                                </td>
                                <td
                                  class='arrow_end'
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => deleteReport(item?._id)}
                                >
                                  <i class='la la-trash'></i>
                                </td>
                              </tr>
                            </>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Page Content --> */}

      {/* <!-- /Page Wrapper --> */}
      <>
        <Modal show={selectcontractor} onHide={handleClose} animation={false}>
          <Modal.Header closeButton>
            <Modal.Title>Update Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='area_text'>
              <div className='form-group'>
                <label for='w3review'>Chats</label>

                <textarea
                  id='w3review'
                  onChange={(e) => setComments(e.target.value)}
                  value={comments}
                  className='form-control'
                  name='w3review'
                  rows='4'
                  cols='40'
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className='btn custom_btn mr-3 btn-md'
              onClick={handleUpdateClose}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showDelete} onHide={handledeleteClose} animation={false}>
          <Modal.Header closeButton className='b-none'></Modal.Header>
          <Modal.Body className='p-4 main_modal'>
            <div className='text_del text-center'>
              <h3>Are you sure?</h3>
            </div>
            <div className='d-flex pb-2 width_cus mt-4 justify-content-around'>
              <Button
                variant='secondary'
                className='btn btn-primary '
                onClick={handledeleteClose}
              >
                Cancel
              </Button>
              <Button
                variant='danger'
                onClick={() => handleButtonDeleteClick(del)}
              >
                Delete
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </div>
  );
}

export default Report_overview;
