import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import SortIcon from '@material-ui/icons/ArrowDownward';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import moment from 'moment';
import { getAllJobSections } from '../../Redux/Action/SuperAdminAction';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import socketio from 'socket.io-client';
import ReactPaginate from 'react-paginate';

const socket = socketio.connect(process.env.REACT_APP_SOCKET_URL);
function JobSection() {
  const dispatch = useDispatch();

  const allJobSection = useSelector((state) => state?.adminList?.allJobsection);
  const GetJob_MetaData = useSelector((state) => state?.adminList?.Jobsection_MetaData);


  const [searchInput, setSearchInput] = useState('');
  const [searchData, setSearchData] = useState('');
  var [secdata, setSecData] = useState(allJobSection);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [page, setPage] = useState(1);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0');
  var yyyy = today.getFullYear();

  today = mm + '/' + dd + '/' + yyyy;

  const handleFilter = (dateCheck) => {
    if (dateCheck == 'overdue') {
      var newArray = allJobSection.filter(function (el) {
        return new Date(today) > new Date(el?.completedBy);
      });
      setSecData(newArray);
      console.log(newArray, 'overdue');
    } else if (dateCheck == 'under') {
      var weeks = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);

      var dd = String(weeks.getDate()).padStart(2, '0');
      var mm = String(weeks.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = weeks.getFullYear();

      weeks = mm + '/' + dd + '/' + yyyy;
      var newArray = allJobSection.filter(function (el) {
        return (
          new Date(el?.completedBy) >= new Date(today) &&
          new Date(el?.completedBy) <= new Date(weeks)
        );
      });
      setSecData(newArray);
      console.log(newArray, 'under');
    } else if (dateCheck == 'over') {
      var weeks = new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000);

      var dd = String(weeks.getDate()).padStart(2, '0');
      var mm = String(weeks.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = weeks.getFullYear();
      weeks = mm + '/' + dd + '/' + yyyy;

      var newArray = allJobSection.filter(function (el) {
        return new Date(weeks) < new Date(el?.completedBy);
      });
      setSecData(newArray);
    }
    handleClose();
  };


  const dataSearch = { secdata: secdata };

  const handlePageClick = (event) => {
    setPage(event.selected + 1)
  };

  const columns = [
    // {
    //   id: 1,
    //   name: 'SECTION NUMBER',
    //   selector: (row) => row?.section_number,
    //   sortable: true,
    //   reorder: true,
    // },
    {
      id: 2,
      name: 'Orchard Number',
      selector: (row) => row?.chard_number,
      sortable: true,
      reorder: true,
    },
    {
      id: 3,
      name: 'CLIENT NAME',
      selector: (row) => `${row?.job_id?.clientId?.clientName}`,
      sortable: true,
      reorder: true,
    },
    {
      id: 4,
      name: 'CONTRACTOR NAME',
      selector: (row) =>
        row?.contractorId?.firstName == undefined
          ? 'No contractor assigned'
          : `${row?.contractorId?.firstName} ${row?.contractorId?.lastName}`,
      sortable: true,
      reorder: true,
    },

    {
      id: 5,
      name: (
        <div className='custom-drop-td'>
          <Button
            style={{ color: 'white' }}
            aria-controls='simple-menu'
            aria-haspopup='true'
            onClick={handleClick}
          >
            DUE DATE
          </Button>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              style={{ fontSize: '12px' }}
              onClick={() => handleFilter('over')}
            >
              Over 2 weeks
            </MenuItem>
            <MenuItem
              style={{ fontSize: '12px' }}
              onClick={() => handleFilter('under')}
            >
              Under 2 weeks
            </MenuItem>
            <MenuItem
              style={{ fontSize: '12px' }}
              onClick={() => handleFilter('overdue')}
            >
              Overdue
            </MenuItem>
          </Menu>
          <ArrowDropDownIcon />
        </div>
      ),
      selector: (row) => moment(row?.completedBy).format('D MMM YYYY'),
    },

    {
      id: 6,
      name: 'JOB TYPE',
      selector: (row) => row?.jobType,
      sortable: true,
      reorder: true,
    },
    {
      id: 7,
      name: 'JOB STATUS',
      cell: (row) =>
        row?.jobstatus == 1
          ? 'Created'
          : row?.jobstatus == 2
            ? 'Accepted'
            : row?.jobstatus == 3
              ? 'Started'
              : row?.jobstatus == 4
                ? 'Paused'
                : row?.jobstatus == 5 && 'Completed',
      selector: (row) => row?.jobstatus,
      sortable: true,
      reorder: true,
    },
    {
      id: 8,
      name: 'ACTIONS',
      cell: (row) => (
        //console.log(`/jobs/jobsdetail/${row?.job_id}#${row?._id}`,"</HashLink>"),
        <>
          <HashLink to={`/jobs/jobsdetail/${row?.job_id?._id}#${row?._id}`}>
            <span
              className='cursor-pointer'
              onClick={() => {
                localStorage.setItem('jobEdit', row?.job_id?._id);
              }}
            >
              <VisibilityIcon style={{ marginLeft: '6px' }} /> View
            </span>
          </HashLink>
          {/* <span
            className='cursor-pointer'
            //onClick={() => handleButtonEditClick(row[0]?._id)}
          >
            <EditIcon style={{ marginLeft: '5px' }} /> Edit
          </span>
          {'     '}
          <span
            className='cursor-pointer'
         //   onClick={() => handledeleteShow(row[0]?.jobinfo?._id)}
          >
            <DeleteIcon style={{ marginLeft: '9px' }} /> Delete
          </span> */}
        </>
      ),
    },
  ];

  const handleEnterClick = (e) => {
    if (e.key == 'Enter') {
      setSearchInput(searchData)
      setPage(1)
    }
  }

  useEffect(() => {
    socket.on('jobsection_list', (payload) => {
      // setChatList([...chatShow, payload]);
    });
  }, [socket]);

  useEffect(() => {
    socket.emit('send_jobsection', ["hello"]);
    dispatch(getAllJobSections(page, searchInput.trim()));
  }, [page, searchInput]);

  useEffect(() => {
    setSecData(allJobSection);
  }, [allJobSection]);

  return (
    <div className='page-wrapper'>
      <div className='content container-fluid'>
        <div className='page-header '>
          <div className='row align-items-center'>
            <div className='col'>
              <h3 className='page-title '>Job Section</h3>
            </div>
          </div>
        </div>
        <div class='page-header bnone'>
          <div class='row align-items-center d-flex'>
            <div className='col-md-3'>
              <div className='search-input-in'>
                <input
                  type='text'
                  onChange={(e) => setSearchData(e.target.value)}
                  onKeyDown={(e) => handleEnterClick(e)}
                  class='form-control border-l-none'
                  placeholder='Keyword Search..'
                />
              </div>
            </div>
            <div className='col-md-2'>
              <select className='select-c-in'>
                <option>Client</option>
                <option>Client</option>
                <option>Client</option>
              </select>
            </div>
            <div className='col-md-2'>
              <select className='select-c-in'>
                <option>Contractor </option>
                <option>Contractor </option>
                <option>Contractor </option>
              </select>
            </div>
            <div className='col-md-1'></div>
            <div className='col-md-2'>
              <select className='select-c-in'>
                <option>Due Form </option>
                <option>Due Form </option>
                <option>Due Form </option>
              </select>
            </div>
            <div className='col-md-2'>
              <select className='select-c-in'>
                <option>Due To </option>
                <option>Due To </option>
                <option>Due To </option>
              </select>
            </div>

          </div>
          {/* <div class='row align-items-center d-flex'>
            <div class='col-md-6'>
            </div>
            <div class='col-md-6'>
              <div class='input-group'>
                <div class='input-group-prepend'>
                  <span class='input-group-text'>
                    <i class='fa fa-search' aria-hidden='true'></i>
                  </span>
                </div>
                <input
                  type='text'
                  onChange={(e) => setSearchInput(e.target.value)}
                  class='form-control border-l-none'
                  placeholder='Search'
                />
              </div>
            </div>
          </div> */}
        </div>
        <div className='row'>
          <div className='col-md-12 d-flex'>
            <div className=' card-table flex-fill'>
              <div className='card-body job_sec-table-second'>
                <div className='table-responsive'>
                  <DataTable
                    columns={columns}
                    data={dataSearch?.secdata}
                    defaultSortFieldId={1}
                    sortIcon={<SortIcon />}
                  //  pagination
                  />

                  <ReactPaginate
                    nextLabel="Next >"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={GetJob_MetaData?.total / 30}
                    previousLabel="< Previous"
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    activeClassName="active"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobSection;
