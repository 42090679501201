import React from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { editFaq } from "../../Redux/Action/SuperAdminAction";
function EditFaq() {
    const history = useHistory();
    const location = useLocation()

    const { id } = useParams()
    console.log(id);
    const dispatch = useDispatch();

    const validate = (values) => {
        const errors = {};
        if (!values.question) {
            errors.question = "Required*";
        }
        if (!values.answer) {
            errors.answer = "Required*";
        }

        return errors;
    };
    const query = new URLSearchParams(location.search);

    const question = query.get('question');
    const answer = query.get('answer');

    const formik = useFormik({
        initialValues: {
            question: question,
            answer: answer,
        },
        validate,
        onSubmit: async (values) => {
            values['_id'] = id
            try {
                dispatch(editFaq(values));
                history.push("/faqs")
            } catch (error) {
                console.log(error);
            }
        },
    });


    return (
        <div className="page-wrapper">
            <form className="content container-fluid" onSubmit={formik.handleSubmit}>
                <div className="content container-fluid">
                    <div className="page-header second-page-header">
                        <div className="row align-items-center">
                            <div className="col">
                                <h3 className="page-title second-page-title">Edit Question</h3>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="col-form-label">
                                    Question <span className="text-danger">*</span>
                                </label>
                                <input className="form-control"
                                    type="text"
                                    name='question'
                                    onChange={formik.handleChange}
                                    value={formik.values.question}
                                />
                                {formik.errors.question && (
                                    <p className="formik-error">
                                        {formik.errors.question}
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group">
                                <label className="col-form-label">
                                    Answer <span className="text-danger">*</span>
                                </label>
                                <textarea class="form-control" rows="3"
                                    onChange={formik.handleChange}
                                    name="answer"
                                    value={formik.values.answer}
                                ></textarea>
                                {formik.errors.answer && (
                                    <p className="formik-error">
                                        {formik.errors.answer}
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="border-t-c">
                        <div className="col-md-12">
                            <div className="mt-3">
                                <button className="btn custom_btn  mr-3 btn-md"
                                    onClick={() => {
                                        history.push('/faqs')
                                    }}
                                >Cancel</button>
                                <button className="btn btn-primary btn-md" htmlType="submit">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default EditFaq;
